<template>

<app-content :loading="is.loading && !is.initialised">

	<app-content-head :tabs="tabs" title="Discover" subtitle="Find opponents and events that match your particular interests.">

		<app-content-head-actions-item text="Add" icon="create" v-on:click="onAddClick" v-if="collection.length" />

	</app-content-head>

	<app-content-body :is-call="!collection.length">

		<app-content-call v-if="!collection.length" icon="matchmaking" title="What games do you want to play?" text="Add your interests, as general or specific as you want, so we can tailor your suggestions and match you with other attendees." button="Add interests" v-on:click="onAddClick" />

		<app-table v-if="collection.length">

			<app-table-head :layout="layout">

				<app-table-column text="Name" />
				<app-table-column align="center" text="Games" />
				<app-table-column align="center" text="Suggestions" />
				<app-table-column align="center" text="Matchmaker" />

			</app-table-head>

			<app-table-body>

				<app-table-row v-on:click="onEditClick(item.id)" :layout="layout" v-for="item in collection" :key="item.id">

					<app-table-cell-text :text="item.name" />
					<app-table-cell-text :text="item.count.games" align="center" />
					<app-table-cell-text :text="item.count.suggestions" align="center" />
					<app-table-cell-text :text="item.count.matches" align="center" />

				</app-table-row>

			</app-table-body>

		</app-table>

	</app-content-body>

</app-content>

</template>

<script>

import mixCollection from '@/mixin/collection'

export default {

	mixins: [mixCollection],

	data: function() {

		return {
			endpoint: 'convention/schedule/discover/interests',
			live: 'convention/schedule/discover/interests',
			layout: 'auto 100px 100px 100px'
		}

	},

	computed: {

		tabs: function() {

			return [
				{ name: 'Convention.Schedule.Discover', text: 'Suggestions' },
				{ name: 'Convention.Schedule.Discover.Matchmaker', text: 'Matchmaker' },
				{ name: 'Convention.Schedule.Discover.Proposals', text: 'Proposals' },
				{ name: 'Convention.Schedule.Discover.Interests', text: 'Interests' }
			]

		}

	},

	methods: {

		onAddClick: function() {

			this.$router.push({
				name: 'Convention.Schedule.Discover.Interest',
				params: {
					id: 'new'
				}
			})

		},

		onEditClick: function(id) {

			this.$router.push({
				name: 'Convention.Schedule.Discover.Interest',
				params: {
					id: id
				}
			})

		}

	}

}

</script>

<style scoped>

</style>